import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sortBy, orderBy } from "lodash";

import api from "../../utils/apiClient";
import getUrlQueryString from "../../utils/getUrlQueryString";

export interface BeneficiaryState {
  loading: any;
  beneficiaries: any;
  beneficiaryLoading: boolean;
  beneficiaryBtnDisable: boolean;
  selectBeneficiary: boolean;
  beneficiaryBtnLoading: boolean;
  NickNameBtnLoading: boolean;
  addBeneficiarydetails: any;
  requirementfields: any;
  cashPickup: any;
  deliverySelectoptions: any;
  transferType: any;
  country: any;
  currency: any;
  collectionMode: any;
  beneficiaryDetails: any;
  entityType: any;
  providerMethod: any;
  servicesOptions: any;
  countryOptions: any;
  currenciesOptions: any;
  collectionModeOptions: any;
  countryList: any;
  allcountries: any;
  Providers: any;
  banks: any;
  Reasons: any;
  Relations: any;
  countries: any;
  step: any;
  isInternational: boolean;
  BankDetails: any;
  NickNameIsvaild: boolean;
  benDetails: any;
  benAllDetails: any;
  newBen: any;
  newBenBankDet: any;
  providerId: any;
  setRelation: any;
  setReason: any;
  providerName: any;
  idType: any;
  pageData: any;
  branchId: any;
  topUpPacks: any;
  isBenificiaryListShow: any;
  isBankListShow: any;
  isRecipientListShow: any;
  isBankSelected: boolean;
  isMissingFields: any;
  isDispayBankScreen: any;
}

const initialState: BeneficiaryState = {
  loading: {
    overlayLoader: false,
    bankDetailsLoader: false,
    saveNewBenLoader: false,
  },
  beneficiaries: [],
  beneficiaryLoading: false,
  beneficiaryBtnLoading: false,
  NickNameBtnLoading: false,
  NickNameIsvaild: false,
  beneficiaryBtnDisable: true,
  selectBeneficiary: false,
  transferType: null,
  country: null,
  isMissingFields: false,
  isDispayBankScreen: false,
  currency: null,
  collectionMode: null,
  beneficiaryDetails: null,
  entityType: null,
  providerMethod: null,
  servicesOptions: [],
  countryOptions: [],
  currenciesOptions: [],
  collectionModeOptions: [],
  countryList: [],
  allcountries: [],
  Providers: [],
  Reasons: [],
  Relations: [],
  countries: [],
  step: "SelectService",
  requirementfields: [],
  banks: {},
  isInternational: false,
  isBenificiaryListShow: true,
  isBankListShow: true,
  isRecipientListShow: true,
  isBankSelected: false,
  BankDetails: {},
  addBeneficiarydetails: {
    signUpRegType: "",
    userBeneficiary: {
      step: 1,
      beneficiaryRequirements: [],
      beneficiaryDetails: {},
      provider: {},
      isInternational: false,
      Bankdetails: {},
    },
    collectionTypes: "",
    providerNames: {},
    providersData: { data: [], passQueryBankingDetail: {} },
    userAddBeneficiaryData: {},
    deliveryDetails: {
      collectionMethod: "",
      deliveryMethod: "",
    },
    mobileTopupData: {},
    isShow: true,
  },
  cashPickup: {
    availableBranches: [],
  },
  deliverySelectoptions: {},
  benDetails: {},
  benAllDetails: {},
  newBen: false,
  newBenBankDet: {},
  providerId: "",
  setRelation: "",
  setReason: "",
  providerName: "",
  idType: "",
  pageData: {},
  branchId: "",
  topUpPacks: [],
};

export const beneficiaryService = createAsyncThunk(
  "Account/Configuration",
  async () => {
    const response = await api.get("Account/Configuration");
    return response.data;
  }
);

export const getBeneficiaryList = createAsyncThunk(
  "Beneficiary/ListBeneficiary",
  async (data: any) => {
    const response = await api.get("Beneficiary/ListBeneficiary?", { data });
    return response.data;
  }
);

export const getBeneficiaryListTransaction = createAsyncThunk(
  "Beneficiary/ListBeneficiary",
  async (data: any) => {
    const query = `?ProviderId=${data.ProviderId}${
      data.BranchId ? `&BranchId=${data.BranchId}` : ""
    }&CurrencyCode=${data.CurrencyCode}&CountryIso3=${
      data.CountryIso3
    }&amount= ${data.amount}&ServiceCode=${data.ServiceCode}&collectionMode=${
      data.collectionMode
    }&International=${data.International}`;
    const response = await api.get(`Beneficiary/ListBeneficiary${query}`);
    return response.data;
  }
);

export const getRecipientsTransaction = createAsyncThunk(
  "Beneficiary/ListBeneficiary",
  async (data: any) => {
    const query = `?CurrencyCode=${data.CurrencyCode}&CountryIso3=${data.CountryIso3}&ServiceCode=${data.ServiceCode}&CollectionMode=${data.CollectionMode}`;
    const response = await api.get(`Beneficiary/ListBeneficiary${query}`);
    return response.data;
  }
);
export const getRecipientsAirtimeTopup = createAsyncThunk(
  "Beneficiary/ListBeneficiary",
  async (data: any) => {
    const query = `?CurrencyCode=${data.CurrencyCode}&CountryIso3=${data.CountryIso3}&ServiceCode=${data.ServiceCode}`;
    const response = await api.get(`Beneficiary/ListBeneficiary${query}`);
    return response.data;
  }
);

export const getBeneficiariesList = createAsyncThunk(
  "Beneficiary/ListBeneficiaryPagination",
  async (data: any) => {
    const response = await api.get(
      `Beneficiary/ListBeneficiaryPagination${getUrlQueryString(data)}`
    );
    return response;
  }
);

export const getBeneficiary = createAsyncThunk(
  "Beneficiary/GetBeneficiary",
  async (id: any) => {
    const response = await api.get(`Beneficiary/GetBeneficiary?Id=${id}`);
    return response.data;
  }
);

export const deleteBeneficiary = createAsyncThunk(
  "Beneficiary/DeleteBeneficiary",
  async ({ id, canCancelTransaction = false }: any) => {
    const response = await api.get(
      `Beneficiary/DeleteBeneficiary?Id=${id}${
        canCancelTransaction
          ? "&IsCancelScheduledTransaction=" + canCancelTransaction
          : ""
      }`
    );
    return response && id;
  }
);

export const validateBeneficiaryNickName = createAsyncThunk(
  "Beneficiary/ValidateBeneficiaryNickName",
  async (name: any) => {
    const response = await api.get(
      `Beneficiary/ValidateBeneficiaryNickName?nickName=${name}`
    );
    return response;
  }
);

export const beneficiaryRequirements = createAsyncThunk(
  "Beneficiary/BeneficiaryRequirements",
  async (data: any) => {
    const response = await api.get(
      `Beneficiary/BeneficiaryRequirements?international=${data.tranferType}&ProviderId=${data.id}&toCountry=${data.toCountry}&toCurrencyCode=${data.toCurrencyCode}&entityType=${data.entityType}&servicecode=${data.serviceType}&collectionMode=${data.collectionMode}`
    );
    let res = sortBy(response.data, [(o) => o.order]);
    return res;
  }
);
export const beneficiaryMobileMoneyRequirements = createAsyncThunk(
  "Beneficiary/BeneficiaryRequirements",
  async (data: any) => {
    const response = await api.get(
      `Beneficiary/BeneficiaryRequirements?entityType=${data.entityType}&servicecode=${data.serviceType}&collectionMode=${data.collectionMode}`
    );
    let res = sortBy(response.data, [(o) => o.order]);
    return res;
  }
);
export const airtimeTopupRequirements = createAsyncThunk(
  "Beneficiary/BeneficiaryRequirements",
  async (data: any) => {
    const response = await api.get(
      `Beneficiary/BeneficiaryRequirements?international=${data.tranferType}&ProviderId=${data.id}&toCountry=${data.toCountry}&toCurrencyCode=${data.toCurrencyCode}&entityType=${data.entityType}&servicecode=${data.serviceType}`
    );
    let res = sortBy(response.data, [(o) => o.order]);
    return res;
  }
);

export const getBankdetails = createAsyncThunk(
  "Bank/GetBankDetails",
  async (data: any) => {
    const response = await api.get(
      `Bank/GetBankDetails?international=${data.tranferType}&parameter=${data.parameter}&value=${data.value}&countryCode=${data.toCountry}&providerId=${data.id}`
    );

    return response;
  }
);

export const postBankingdetails = createAsyncThunk(
  "Bank/GetBankingDetails",
  async (data: any) => {
    const response = await api.post("Bank/GetBankingDetails", { data });
    return response;
  }
);

export const saveBeneficiarydeatails = createAsyncThunk(
  "Beneficiary/SaveBeneficiary",
  async (data: any) => {
    const response = await api.post("Beneficiary/SaveBeneficiary", { data });
    return response;
  }
);
export const createBeneficiaryBankRequest = createAsyncThunk(
  "Beneficiary/createBeneficiaryBankRequest",
  async (data: any) => {
    const response = await api.post(
      `Beneficiary/createBeneficiaryBankRequest?beneficiaryId=${data.id}`,
      { data }
    );
    return response;
  }
);

export const deleteAndSaveBeneficiary = createAsyncThunk(
  "Beneficiary/DeleteAndSaveBeneficiary",
  async (data: any) => {
    const response = await api.post(
      `Beneficiary/DeleteAndSaveBeneficiary`,
      { data }
    );
    return response;
  }
);

export const editBeneficiary = createAsyncThunk(
  "Beneficiary/UpdateBeneficiary",
  async (data: any) => {
    const response = await api.post(
      `Beneficiary/UpdateBeneficiary?beneficiaryId=${data.id}`,
      { data }
    );
    return response;
  }
);

export const createBeneficiary = createAsyncThunk(
  "Beneficiary/CreateBeneficiary",
  async (data: any) => {
    const response = await api.post("Beneficiary/CreateBeneficiary", { data });
    return response;
  }
);

export const beneficiarySlice = createSlice({
  name: "beneficiary",
  initialState,
  reducers: {
    getBeneficiarysList: (state, actions: PayloadAction<any>) => {
      state.beneficiaries = actions.payload;
    },
    getSelectBeneficiary: (state, actions: PayloadAction<any>) => {
      state.selectBeneficiary = actions.payload;
    },
    isNewBeneficiary: (state, actions: PayloadAction<any>) => {
      state.newBen = actions.payload;
    },
    newBeneficiaryBankDetails: (state, actions: PayloadAction<any>) => {
      state.newBenBankDet = actions.payload;
    },
    selectedBenDetails: (state, actions: PayloadAction<any>) => {
      state.benAllDetails = actions.payload;
    },
    setProviderId: (state, actions: PayloadAction<any>) => {
      state.providerId = actions.payload;
    },
    setBranchId: (state, actions: PayloadAction<any>) => {
      state.branchId = actions.payload;
    },
    setProviderName: (state, actions: PayloadAction<any>) => {
      state.providerName = actions.payload;
    },
    setEntityType: (state, actions: PayloadAction<any>) => {
      state.entityType = actions.payload;
    },
    setProviderMethod: (state, actions: PayloadAction<any>) => {
      state.providerMethod = actions.payload;
    },
    setPageData: (state, actions: PayloadAction<any>) => {
      state.pageData = actions.payload;
    },
    setIsinternational: (state, action) => {
      state.isInternational = action.payload;
    },
    setIsBankSelected: (state, action) => {
      state.isBankSelected = action.payload;
    },
    setServicesOptions: (state, action) => {
      state.servicesOptions = action.payload;
    },
    setCountryOptions: (state, action) => {
      state.countryOptions = action.payload;
    },
    setCountryList: (state, action) => {
      state.countryList = action.payload;
    },
    setCurrenciesOptions: (state, action) => {
      state.currenciesOptions = action.payload;
    },
    setCollectionModeOptions: (state, action) => {
      state.collectionModeOptions = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
      let sortedList = orderBy(state.countries, [(o) => o.name]);
      state.countries = sortedList;
    },
    setProviders: (state, action) => {
      state.Providers = action.payload;
    },
    setTopUpPacks: (state, action) => {
      state.topUpPacks = action.payload;
    },
    setBank: (state, action: PayloadAction<any>) => {
      state.banks = action.payload;
    },
    setIdDocType: (state, action) => {
      state.idType = action.payload;
    },
    setReasons: (state, action) => {
      state.Reasons = action.payload;
    },
    setRelations: (state, action) => {
      state.Relations = action.payload;
    },
    setBeneficiaryDetails: (state, action) => {
      state.beneficiaryDetails = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setRelationship: (state, action) => {
      state.setRelation = action.payload;
    },
    setReason: (state, action) => {
      state.setReason = action.payload;
    },
    setHandleBeneficiaryDisplay: (state, action) => {
      state.isBenificiaryListShow = action.payload;
    },
    setHandleBankDisplay: (state, action) => {
      state.isBankListShow = action.payload;
    },
    setHandleRecipientDisplay: (state, action) => {
      state.isRecipientListShow = action.payload;
    },
    setIsMissingFields: (state, action) => {
      state.isMissingFields = action.payload;
    },
    setIsDispayBankScreen: (state, action) => {
      state.isDispayBankScreen = action.payload;
    },
    setbeneficiaryConfiguration: (state, action) => {
      const {
        transferType,
        country,
        currency,
        entityType,
        collectionMode,
        step,
      } = action.payload;

      state.transferType = transferType;
      state.country = country;
      state.currency = currency;
      state.entityType = entityType;
      state.collectionMode = collectionMode;
      state.step = step;
    },

    resetbeneficiaryConfiguration: (state, action) => {
      state.Providers = [];
      state.beneficiaryDetails = {};
      state.isInternational = false;
      state.isBankSelected = false;
      state.isDispayBankScreen = false;
      state.banks = [];
      state.transferType = null;
      state.country = null;
      state.currency = null;
      state.entityType = null;
      state.collectionMode = null;
      state.requirementfields = [];
      state.benDetails = {};
      state.benAllDetails = {};
      state.newBenBankDet = {};
      state.BankDetails = {};
      state.providerId = "";
      state.setRelation = "";
      state.setReason = "";
      state.providerName = "";
      state.idType = "";
      state.pageData = {};
      state.branchId = "";
      state.cashPickup.availableBranches = [];
      state.topUpPacks = [];
      state.isBenificiaryListShow = true;
      state.isBankListShow = true;
      state.isRecipientListShow = true;
      state.providerMethod = 'local';
    },
    resetRequirements: (state) => {
      state.requirementfields = [];
    },
    resetBenDetails: (state) => {
      state.benDetails = {};
      state.beneficiaryDetails = {};
      state.newBenBankDet = {};
      state.BankDetails = {};
      state.setRelation = "";
      state.setReason = "";
      state.providerName = "";
      state.idType = "";
      state.branchId = "";
    },
    resetReason: (state) => {
      state.setReason = "";
    },
    resetBenkDetails: (state) => {
      state.BankDetails = {};
    },
    setBankDetails: (state, action) => {
      state.BankDetails = action.payload;
    },
    getSignUpRegType: (state, action) => {
      state.addBeneficiarydetails.signUpRegType = action.payload;
    },
    addBeneficiary: (state, action) => {
      state.addBeneficiarydetails.userBeneficiary = action.payload;
    },
    beneficiaryTranferProviders: (state, action) => {
      state.addBeneficiarydetails.providerNames = action.payload;
    },
    handleProvidersData: (state, action) => {
      state.addBeneficiarydetails.providersData.data = action.payload;
    },
    getpassQueryBankingDetail: (state, action) => {
      state.addBeneficiarydetails.providersData.passQueryBankingDetail =
        action.payload;
    },
    handleCollectionMethodData: (state, action) => {
      state.addBeneficiarydetails.deliveryDetails.collectionMethod =
        action.payload;
    },
    handleCollectionTypes: (state, action) => {
      state.addBeneficiarydetails.collectionTypes = action.payload;
    },

    handleDeliveryMethodData: (state, action) => {
      state.addBeneficiarydetails.deliveryDetails.deliveryMethod =
        action.payload;
    },

    getCashPickup: (state, action) => {
      state.cashPickup.availableBranches = action.payload;
    },
    handleMobileTopUp: (state, action) => {
      state.addBeneficiarydetails.mobileTopupData = action.payload;
    },
    handleBeneficiaryFields: (state, action) => {
      state.requirementfields = action.payload.data;
    },
    handleBeneficiarySummary: (state, action) => {
      state.addBeneficiarydetails.userAddBeneficiaryData = action.payload;
    },
    handleBackToBeneficiary: (state, action) => {
      state.addBeneficiarydetails.isShow = action.payload;
    },

    deleteBeneficiarys: (state, actions: PayloadAction<any>) => {
      state.beneficiaries = actions.payload;
    },
    handleDeliverymethods: (state, actions: PayloadAction<any>) => {
      state.deliverySelectoptions = actions.payload;
    },
    handleGoBack: (state, actions: PayloadAction<any>) => {
      let { addBeneficiarydetails } = state;
      let data = { ...addBeneficiarydetails.userBeneficiary };
      data.step = data.step - 1;
      addBeneficiarydetails.userBeneficiary = data;
      state.addBeneficiarydetails = addBeneficiarydetails;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBeneficiariesList.pending, (state) => {
        state.beneficiaryLoading = true;
      })
      .addCase(getBeneficiariesList.rejected, (state) => {
        state.beneficiaryLoading = false;
      })
      .addCase(getBeneficiariesList.fulfilled, (state, action) => {
        state.beneficiaryLoading = false;
        state.beneficiaries = action.payload;
      })
      .addCase(editBeneficiary.pending, (state) => {
        state.loading.saveNewBenLoader = true;
      })
      .addCase(editBeneficiary.rejected, (state) => {
        state.loading.saveNewBenLoader = false;
      })
      .addCase(editBeneficiary.fulfilled, (state, action) => {
        state.loading.saveNewBenLoader = false;
        state.beneficiaries = action.payload.data;
        state.benDetails = action.payload.data;
      })
      .addCase(validateBeneficiaryNickName.pending, (state) => {
        state.NickNameBtnLoading = true;
        state.NickNameIsvaild = false;
      })
      .addCase(validateBeneficiaryNickName.rejected, (state) => {
        state.NickNameBtnLoading = false;
        state.NickNameIsvaild = false;
      })
      .addCase(validateBeneficiaryNickName.fulfilled, (state) => {
        state.NickNameBtnLoading = false;
        state.NickNameIsvaild = true;
      })
      .addCase(saveBeneficiarydeatails.pending, (state) => {
        state.beneficiaryBtnLoading = true;
      })
      .addCase(saveBeneficiarydeatails.rejected, (state) => {
        state.beneficiaryBtnLoading = false;
      })
      .addCase(createBeneficiaryBankRequest.rejected, (state) => {
        state.beneficiaryBtnLoading = false;
      })
      .addCase(createBeneficiaryBankRequest.fulfilled, (state, action) => {
        state.beneficiaryBtnLoading = false;
      })
      .addCase(beneficiaryService.pending, (state) => {
        state.beneficiaryLoading = true;
      })
      .addCase(beneficiaryService.rejected, (state) => {
        state.beneficiaryLoading = false;
      })
      .addCase(beneficiaryService.fulfilled, (state, action) => {
        state.beneficiaryLoading = false;
      })

      .addCase(createBeneficiary.pending, (state) => {
        state.beneficiaryLoading = true;
        state.loading.saveNewBenLoader = true;
      })
      .addCase(createBeneficiary.rejected, (state) => {
        state.beneficiaryLoading = false;
        state.loading.saveNewBenLoader = false;
      })
      .addCase(createBeneficiary.fulfilled, (state, action) => {
        state.beneficiaryLoading = false;
        state.loading.saveNewBenLoader = false;
        state.beneficiaries = action.payload.data;
        state.benDetails = action.payload.data;
      })
      .addCase(getBeneficiaryList.pending, (state) => {
        state.beneficiaryLoading = true;
      })
      .addCase(getBeneficiaryList.rejected, (state) => {
        state.beneficiaryLoading = false;
      })
      .addCase(getBeneficiaryList.fulfilled, (state, action) => {
        state.beneficiaryLoading = false;
        state.beneficiaries = action.payload;
      })
      .addCase(getBeneficiary.pending, (state) => {
        state.beneficiaryLoading = true;
      })
      .addCase(getBeneficiary.rejected, (state) => {
        state.beneficiaryLoading = false;
      })
      .addCase(getBeneficiary.fulfilled, (state, action) => {
        state.beneficiaryLoading = false;
        state.benDetails = action.payload;
      })
      .addCase(deleteBeneficiary.pending, (state) => {
        state.beneficiaryLoading = true;
      })
      .addCase(deleteBeneficiary.rejected, (state) => {
        state.beneficiaryLoading = false;
      })
      .addCase(deleteBeneficiary.fulfilled, (state, action) => {
        state.beneficiaryLoading = false;
      })
      .addCase(beneficiaryRequirements.pending, (state) => {
        state.beneficiaryLoading = true;
        state.beneficiaryBtnDisable = true;
      })
      .addCase(beneficiaryRequirements.rejected, (state) => {
        state.beneficiaryLoading = false;
        state.beneficiaryBtnDisable = false;
      })
      .addCase(beneficiaryRequirements.fulfilled, (state, action: any) => {
        state.beneficiaryBtnDisable = false;
        state.beneficiaryLoading = false;
        state.requirementfields = action.payload;
      })
      .addCase(getBankdetails.pending, (state) => {
        state.beneficiaryBtnLoading = true;
        state.loading.bankDetailsLoader = true;
        state.BankDetails = {};
      })
      .addCase(getBankdetails.rejected, (state) => {
        state.beneficiaryBtnLoading = false;
        state.loading.bankDetailsLoader = false;
      })
      .addCase(getBankdetails.fulfilled, (state, action) => {
        state.beneficiaryBtnLoading = false;
        state.loading.bankDetailsLoader = false;
        state.BankDetails = action.payload.data;
      })
      .addCase(postBankingdetails.pending, (state) => {
        state.beneficiaryBtnLoading = true;
        state.loading.bankDetailsLoader = true;
        state.BankDetails = {};
      })
      .addCase(postBankingdetails.rejected, (state) => {
        state.beneficiaryBtnLoading = false;
        state.loading.bankDetailsLoader = false;
      })
      .addCase(postBankingdetails.fulfilled, (state, action) => {
        state.beneficiaryBtnLoading = false;
        state.loading.bankDetailsLoader = false;
        state.BankDetails = action.payload.data;
      })
      .addCase(deleteAndSaveBeneficiary.pending, (state) => {
        state.loading.saveNewBenLoader = true;
      })
      .addCase(deleteAndSaveBeneficiary.rejected, (state) => {
        state.loading.saveNewBenLoader = false;
      })
      .addCase(deleteAndSaveBeneficiary.fulfilled, (state, action) => {
        state.loading.saveNewBenLoader = false;
        state.beneficiaries = action.payload.data;
        state.benDetails = action.payload.data;
      });
  },
});

export const {
  getBeneficiarysList,
  getSelectBeneficiary,
  addBeneficiary,
  getSignUpRegType,
  beneficiaryTranferProviders,
  handleProvidersData,
  handleBeneficiaryFields,
  getpassQueryBankingDetail,
  handleCollectionMethodData,
  handleDeliveryMethodData,
  handleBeneficiarySummary,
  handleBackToBeneficiary,
  deleteBeneficiarys,
  handleDeliverymethods,
  handleCollectionTypes,
  handleMobileTopUp,
  handleGoBack,
  getCashPickup,
  setServicesOptions,
  setCountryOptions,
  setCountryList,
  setCollectionModeOptions,
  setCurrenciesOptions,
  setbeneficiaryConfiguration,
  setCountries,
  setProviders,
  setBank,
  setReasons,
  setRelations,
  setBeneficiaryDetails,
  setStep,
  resetbeneficiaryConfiguration,
  setIsinternational,
  resetRequirements,
  selectedBenDetails,
  isNewBeneficiary,
  newBeneficiaryBankDetails,
  resetBenDetails,
  setProviderId,
  resetBenkDetails,
  setBankDetails,
  setRelationship,
  setReason,
  setProviderName,
  resetReason,
  setIdDocType,
  setEntityType,
  setProviderMethod,
  setPageData,
  setBranchId,
  setTopUpPacks,
  setHandleBeneficiaryDisplay,
  setHandleBankDisplay,
  setHandleRecipientDisplay,
  setIsBankSelected,
  setIsMissingFields,
  setIsDispayBankScreen
} = beneficiarySlice.actions;

export default beneficiarySlice.reducer;
