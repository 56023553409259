import {
  Box,
  Heading,
  Flex,
  ListItem,
  UnorderedList,
  Text,
  Link,
} from "@chakra-ui/react";

import goToSection from "../../utils/goToSection";
import { useAppSelector } from "../../redux/hooks";
import moment from "moment";

const AboutUsContent = () => {
  const {tenancyConfigData} = useAppSelector((state) => ({
    tenancyConfigData: state.affiliate.multiTenancyConfigData,
  }));

  const links = [
    {
      id: "privacy-what-is-Mito.Money",
      label: "What is Mito.Money?",
    },
    {
      id: "privacy-what-data-do-we-collect",
      label: "What data do we collect?",
    },
    {
      id: "privacy-why-do-we-collect-your-data",
      label: "Why do we collect your data?",
    },
    {
      id: "privacy-how-do-we-keep-your-data-safe-and-secure",
      label: "How do we keep your data safe and secure?",
    },
    {
      id: "privacy-definitions",
      label: "Definitions",
    },
    {
      id: "privacy-how-long-do-we-keep-your-data",
      label: "How long do we keep your data?",
    },
    {
      id: "privacy-who-do-we-share-your-data-with",
      label: "Who do we share your data with?",
    },
    {
      id: "privacy-what-are-your-rights",
      label: "What are your rights?",
    },
    {
      id: "privacy-legitimate-interest",
      label: "Legitimate interest",
    },
    {
      id: "privacy-cookies",
      label: "Cookies",
    },
    {
      id: "privacy-logging-in-to-Mito.Money-with-your-facebook-account",
      label: "Logging in to Mito.Money with your Facebook account",
    },
    {
      id: "privacy-changes-in-privacy-policy",
      label: "Changes in Privacy Policy",
    },
    {
      id: "privacy-contact-details",
      label: "Contact details",
    },
  ];

  return (
    <Flex
      background='#fff'
      p={{ sm: "20px", lg: "0" }}
    >
      <Box w={{ sm: "100%", lg: "100%" }}>
        <Box>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
          >
            Last updated: 21/05/{moment().subtract(1, 'year').year()}
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Trust and security are a big part of what makes Mito.Money the best way
            to send money internationally.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Your privacy is very important to us, so we’d like to tell you what
            we do to keep your data safe and secure.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            In this policy, we explain how Mito.Money collects, manages, uses and
            protects your data.
          </Heading>
        </Box>
        <Box mt="50px">
          <Heading
            as="p"
            size="xxl_normal"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            id="privacy-what-is-Mito.Money"
          >
            What is Mito.Money?
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Mito.Money is an online money transfer service with the mission to make
            life easier for everyone transferring money around the world.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Mito.Money is a trademark owned by Funtech Global Communications Ltd. A
            registered payment institution in the UK with registration details
            FRN: 815146 MLR NO: 12803115. Mito.Money is powered by Funtech’s
            Universal Remittance Portal (FURP), which is built to manage cross
            border payments and aggregate multiple payment partners worldwide.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            In this Privacy Policy, terms such as “we”, “us” and “Mito.Money” refer
            to Funtech Global Communications Ltd (Company Number 07979772) with
            its registered office located at Devonshire House, Manor way,
            Borehamwood, Hertfordshire, WD6 1QQ, United Kingdom.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            The term “Service” refers to money transfers enabled from our
            website.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            In the language of data protection regulation, Mito.Money is the “data
            controller” of your personal data and you, our customer, are the
            “data subject”. This means that Mito.Money determines the purposes and
            means of processing your personal data, while respecting rights
            concerning your privacy.
          </Heading>
        </Box>
        <Box mt="50px">
          <Heading
            as="p"
            size="xxl_normal"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            id="privacy-what-data-do-we-collect"
          >
            What data do we collect?
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Like most companies, we collect various types of data about our
            customers. Some of it may be considered personal data, which means
            data that make you identifiable as an individual.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            As a regulated financial institution, we are bound by the legal
            requirement to collect, verify and record certain data about you or
            recipients of your transactions. All types of data we gather may be
            used to prevent or detect crime.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We may collect and use the following data:
          </Heading>
          <UnorderedList>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <Text as="b">Basic personal data,</Text> such as (but not limited
              to):
            </Heading>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Date of birth
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Address
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Phone number
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                E-mail address
              </Heading>
            </ListItem>
          </UnorderedList>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            This data is necessary for Mito.Money to provide the Service to you. We
            will request this data when you sign up, before the Service is
            provided.
          </Heading>

          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            <Text as="b">Data for ‘Know Your Customer’ (KYC) regulations,</Text>
            such as (but not limited to):
          </Heading>
          <UnorderedList>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Proof of your identity, like a passport, driving licence,
                national ID card or residence permit
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Proof of your address, like a utility bill or bank statement
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Additional details on the source of funds being sent, like a
                payslip, credit card statement, tax rebate receipt or bank loan
                agreement
              </Heading>
            </ListItem>
          </UnorderedList>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            This data will sometimes be needed to conduct Know Your Customer
            (KYC), Customer Due Diligence (CDD) and security checks, as required
            by local and international regulations. This helps us keep your
            money safe, and we will only request these details when necessary.
          </Heading>

          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            <Text as="b">Data from other third-party sources,</Text> such as
            (but not limited to):
          </Heading>
          <UnorderedList>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Facebook, Twitter or Google profile images and names
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Banks and payment service providers used to transfer money to us
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Advertising networks
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Search engines providers (such as Yahoo.com or Google)
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Credit reference agencies
              </Heading>
            </ListItem>
          </UnorderedList>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Mito.Money collects this data only when you provide the relevant
            permission to social media sites.
          </Heading>

          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            <Text as="b">Technical data,</Text> such as (but not limited to):
          </Heading>
          <UnorderedList>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Page views
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                App downloads
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Operating system
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Browser type
              </Heading>
            </ListItem>
          </UnorderedList>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            This data is used to help us to understand how you use our Service,
            so that we can improve it.
          </Heading>
        </Box>

        <Box mt="50px">
          <Heading
            as="p"
            size="xxl_normal"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            id="privacy-why-do-we-collect-your-data"
          >
            Why do we collect your data?
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We collect your data to personalise and improve our service for you.
            The specific purposes for which we collect your data include:
          </Heading>
          <Heading as="h5" size="lg_medium" variant="primary" mt="2rem">
            Transactional purposes
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We need to collect data in order to process your transactions.
            Without data such as you and your beneficiaries bank account details
            or full name and address, we would be unable to transfer money for
            you.
          </Heading>
          <Heading as="h5" size="lg_medium" variant="primary" mt="2rem">
            Regulatory purposes
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            As regulated financial institutions, both Mito.Money and our partners
            are required to conduct Know Your Customer (KYC) and Customer Due
            Diligence (CDD) checks to comply with our legal and regulatory
            requirements.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            These include our requirements under Anti Money Laundering (AML) and
            Counter Terrorist Financing (CTF) legislation. All of this helps us
            keep our Service safe and secure.
          </Heading>
          <Heading as="h5" size="lg_medium" variant="primary" mt="2rem">
            Marketing purposes
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We may process your personal data to provide you with certain types
            of marketing communication that we believe will be relevant and of
            interest to you. This helps us provide you with a more personalised
            service. This kind of activity is permitted by our ‘legitimate
            interest’ (for more information on legitimate interest, please see
            Section 8 of this Policy). We will always endeavour to make these
            communications relevant and un-intrusive, and you are able to object
            to marketing communication from us at any time.
          </Heading>
          <Heading as="h5" size="lg_medium" variant="primary" mt="2rem">
            Analytical purposes
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We may collect and analyse data such as website or app visit logs to
            improve the quality of our service.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            You do not have to disclose any of the above data to us. However, if
            you choose to withhold certain data, we may not be able to provide
            you with our Service.
          </Heading>
        </Box>

        <Box mt="50px">
          <Heading
            as="p"
            size="xxl_normal"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            id="privacy-how-do-we-keep-your-data-safe-and-secure"
          >
            How do we keep your data safe and secure?
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            All the data that you provide to us is encrypted on our secure
            servers. We restrict access to your data to specific employees of
            Mito.Money who have an important business-related reason for handling
            it. Our communications are encrypted using encryption technologies
            which are based on standard algorithms such as DES, RSA and IDEA.
            These algorithms represent the actual cipher used for an approved
            application. For example, Secure Socket Layer (SSL) uses RSA
            encryption.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            All the data we collect from you or from other sources will always
            be stored in accordance with this Privacy Policy.
          </Heading>
        </Box>

        <Box mt="50px">
          <Heading
            as="p"
            size="xxl_normal"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            id="privacy-definitions"
          >
            Definitions
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            An <Text as="b">algorithm</Text> is a procedure or formula for
            solving a problem. A computer program can be viewed as an elaborate
            algorithm.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            A <Text as="b">cipher</Text> is any method of encrypting data
            (concealing its readability and meaning).
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            <Text as="b">Data</Text> is defined as any information within the
            organisation’s purview, including customer record data, personnel
            data, financial data (budget and payroll), departmental
            administrative data, legal files, research data, proprietary data,
            and all other data that pertains to, or supports the administration
            of the organisation.Data is defined as any information within the
            organisation’s purview, including customer record data, personnel
            data, financial data (budget and payroll), departmental
            administrative data, legal files, research data, proprietary data,
            and all other data that pertains to, or supports the administration
            of the organisation.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            <Text as="b">Data Encryption Standard (DES)</Text> is a widely used
            method of data encryption using a private (secret) key.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Electronic Information Resources include data, networks, computers,
            and other devices that store, or display data, communications
            devices, and software used on such devices.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            <Text as="b">International Data Encryption Algorithm (IDEA)</Text>
            is an encryption algorithm developed at ETH in Zurich, Switzerland.
            It uses a block cipher with a 128-bit key and is generally
            considered to be very secure. It is considered among the best
            publicly known algorithms.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            <Text as="b">Rivest-Shamir-Adleman (RSA)</Text> is an Internet
            encryption and authentication system that uses an algorithm
            developed in 1977 by Ron Rivest, Adi Shamir, and Leonard Adleman.
            The RSA algorithm is the most commonly used encryption and
            authentication algorithm and is included as part of the Web
            browsers.
          </Heading>
        </Box>

        <Box mt="50px">
          <Heading
            as="p"
            size="xxl_normal"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            id="privacy-how-long-do-we-keep-your-data"
          >
            How long do we keep your data?
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Depending on what purpose your data is used for, the length of time
            we keep it may vary. Either way, we will only hold your data if
            necessary, to serve the purpose it is used for.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            <Text as="b">Regulatory</Text>
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We are legally required to keep the data obtained for Know Your
            Customer (KYC), Customer Due Diligence (CDD) and security purposes
            (including transaction records and our communications with you) for
            at least five years after the most recent transaction?.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
            id="privacy-legitimate-interest"
          >
            <Text as="b">Legitimate Interest</Text>
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            When the five years’ retention period and/or legitimate interest no
            longer apply, we will remove your data from our system. For more
            information on legitimate interest, please see Section 8 of this
            Policy.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            <Text as="b">Consent</Text>
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            In situations where you give us specific consent to process certain
            kinds of data, you can withdraw that consent at any time. We will
            then stop processing your data and, if the five years’ retention
            period and/or legitimate interest retention period does not apply,
            we will also erase your data from our system.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We may retain any data mentioned above for a longer period, if
            required to protect the rights, property or safety of Mito.Money or of
            the Service provided by us or our partners.
          </Heading>
        </Box>

        <Box mt="50px">
          <Heading
            as="p"
            size="xxl_normal"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            id="privacy-who-do-we-share-your-data-with"
          >
            Who do we share your data with?
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We share your personal data with third parties only when it is
            necessary for the fulfilment of the Service or to comply with
            applicable laws.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We will never sell your personal data to other organisations.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We work with partners who help us to complete your transactions. If
            they are based outside the EEA, we will share your personal data
            with them only when they apply essential safeguards, or if it has
            been established by EU institutions that the relevant country has an
            appropriate data protection regime in place, or when we otherwise
            ensure that the appropriate level of protection is applied for data
            processing.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            The following are some purposes for which we may share your data
            with third parties:
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            <Text as="b">To fulfil the contract between you and Mito.Money</Text>
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We may share your data with third parties, such as our partners and
            intermediaries, when they are necessary for the fulfilment of the
            Service.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            <Text as="b">When required by law</Text>
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We may share your data when required by law, for example for the
            purposes of security, taxation and criminal investigations.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            <Text as="b">For marketing and communication</Text>
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We may share your data with third parties such as providers of
            customer service tools, marketing campaign tools, email
            communication tools, analytics software (for marketing purposes) and
            data visualisation tools (for analytical purposes).
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            If we sell or buy any business or assets, we may be obliged to share
            your personal data with the prospective seller or buyer.
          </Heading>
        </Box>

        <Box mt="50px">
          <Heading
            as="p"
            size="xxl_normal"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            id="privacy-what-are-your-rights"
          >
            What are your rights?
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            The law gives you several important rights in relation to your
            personal data, which are listed below.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            There are certain exceptions where these rights may be superseded by
            laws and other requirements applicable to regulated authorised
            payment institutions like Mito.Money. An example of this would be the
            obligatory retention period (seen Section 5), which supersedes the
            right to data erasure.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Your rights are:
          </Heading>
          <UnorderedList>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                <Text as="b">The right to be informed: </Text>the right to be
                informed about how we use your personal data
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                <Text as="b">The right of access and data portability: </Text>
                the right to request access to the data that we have about you,
                to obtain the data within a month of asking for it, and to use
                it for your own purposes
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                <Text as="b">The right to rectification: </Text>the right to
                correct, amend or update your personal data if it is wrong or
                has changed (this can usually be done using the settings
                provided on your account)
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                <Text as="b">The right to erasure: </Text>the right to ask us to
                remove the data we hold about you from our records
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                <Text as="b">The right to be forgotten: </Text>the right to
                request erasure of any links to your personal data, or of any
                copy or replication of any public personal data
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                <Text as="b">The right to restrict processing: </Text>the right
                to ask us to stop processing your personal data
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                <Text as="b">The right to object: </Text>the right to object to
                data processing, if you believe that our legitimate interest
                might infringe upon your rights
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                <Text as="b">
                  Rights related to automated decision-making and profiling:
                </Text>
                the right not be subject to individual decisions made solely by
                automated means.
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                <Text as="b">
                  The right to notification in the event of breach:
                </Text>
                the right to be immediately informed if we identify a data
                breach
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                <Text as="b">The right to raise a complaint: </Text>the right to
                contact our Data Protection Officer if you are not happy with
                how we have handled your personal data
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                <Text as="b">
                  The right to lodge a complaint with a supervisory authority:
                </Text>
                the right to submit a complaint to the Information
                Commissioner’s Office (ICO) if you are not satisfied with our
                Data Protection Officer’s response, or if you believe that we
                are not processing your personal data in a lawful way (For more
                details please refer to the Commissioner’s
                <Link color="blue.400" ml="2" href="https://ico.org.uk/">
                  Office website.
                </Link>
              </Heading>
            </ListItem>
          </UnorderedList>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            If you would like to read more about your rights in relation to your
            personal data, please refer to the Information Commissioner’s
            <Link color="blue.400" ml="2" href="https://ico.org.uk/">
              Office website.
            </Link>
          </Heading>
        </Box>

        <Box mt="50px">
          <Heading
            as="p"
            size="xxl_normal"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
          >
            Legitimate interest
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Legitimate interest is a specific legal justification for the
            collection and processing of your personal data. It applies when we
            have reasonable grounds to collect and/or process your personal data
            to improve our Service, as long as this does not infringe on your
            rights.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Our legitimate interest may justify some examples of automated
            decision-making. One of those is our estimated transaction timing,
            which we use to determine the time in which the transfer will reach
            your beneficiary.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We believe that if you are an active customer of Mito.Money, it is in
            your interest to receive occasional information about our Service.
            We may therefore send you communications about offers or promotions
            that we believe are relevant for you based on your previous use of
            the Service. If we notice that you are having problems using our
            service, we may on our own initiative decide to contact you, in some
            cases by phone, to offer help
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We may also process your data to help develop new product features
            that we believe will improve the Service. We may contact you
            occasionally to assess your satisfaction with the service. We may
            also use your data to determine the effectiveness of marketing or
            promotional campaigns. We may also contact you with information
            about any changes in the service, and/ or other important updates.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We will only share anonymised or encrypted data with the third
            parties. We may also provide our partners with anonymous aggregated
            data about our customers for marketing and analytical purposes, to
            help optimise our marketing communications.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            You can object to data processing based on our legitimate interest
            at any time by contacting us at
            <Link color="blue.400" ml="2" href="mailto:help@mito.money">
              help@mito.money
            </Link>
            or by changing the settings on your Mito.Money account.
          </Heading>
        </Box>

        <Box mt="50px">
          <Heading
            as="p"
            size="xxl_normal"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            id="privacy-cookies"
          >
            Cookies
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Cookies are small text files which are stored on your device when
            you access Mito.Money. They allow us to recognise you and store data
            about your past activity and your preferences so that we can
            personalise and improve the service for you.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Cookies and other similar technologies may collect data such as
            language preference, country and previously viewed pages.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            We use the following cookies:
          </Heading>
          <UnorderedList>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                <Text as="b">Necessary cookies: </Text>these cookies enable the
                basic use of our Service e.g. enabling content reserved for
                registered users
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                <Text as="b">Personalisation cookies: </Text>these cookies allow
                our Service to be personalised for you e.g. remembering your
                preferred language or sending country
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                <Text as="b">Analytical cookies: </Text>these cookies allow us
                to see how people use our Service so that we can understand how
                to improve it e.g. seeing how many people are logged in at a
                given time of day
              </Heading>
            </ListItem>
          </UnorderedList>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            By using our Service, you agree to place the cookies described above
            on your device. You have the right to withdraw your consent at any
            time. If you would like to delete our cookies, you can do so by
            changing the settings of your browser.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Blocking or deleting cookies may mean that some features of the
            Service may not be available to you.
          </Heading>
        </Box>

        <Box mt="50px">
          <Heading
            as="p"
            size="xxl_normal"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            id="privacy-logging-in-to-Mito.Money-with-your-facebook-account"
          >
            Logging in to Mito.Money with your Facebook account
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            You can login to Mito.Money using your Facebook profile. This allows us
            to authenticate your identity by obtaining access to:
          </Heading>
          <UnorderedList>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Your e-mail address associated with your Facebook profile
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Your Facebook public profile
              </Heading>
            </ListItem>
          </UnorderedList>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Any of your personal data that we receive from third parties will be
            subject to this Privacy Policy and the privacy policies of those
            third parties.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            By using your Facebook login credentials, your personal data may be
            processed by Facebook marketing tools. An example of this is the
            Facebook tool called ‘Lookalike Audiences’. This helps us to
            identify people who have similar interests or behaviours to those of
            our customers. We may use similar tools provided by Facebook in the
            future.
          </Heading>
        </Box>

        <Box mt="50px">
          <Heading
            as="p"
            size="xxl_normal"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            id="privacy-changes-in-privacy-policy"
          >
            Changes in Privacy Policy
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Mito.Money may change this Privacy Policy from time to time. Any
            changes will be effective immediately unless stated otherwise. The
            date of the most recent update is displayed at the top of the page.
          </Heading>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Any dispute or claim arising in connection with this Privacy Policy
            will be considered in relation to the English version only.
          </Heading>
        </Box>

        <Box mt="50px">
          <Heading
            as="p"
            size="xxl_normal"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            id="privacy-contact-details"
          >
            Contact details
          </Heading>
          <UnorderedList>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                By post: Devonshire House, Manor way, Borehamwood,
                Hertfordshire, WD6 1QQ, United Kingdom.
              </Heading>
            </ListItem>
            <ListItem>
              <Heading
                as="p"
                size="lg_light"
                color="rgb(33, 33, 33)"
                textTransform="initial"
                lineHeight="28px"
                pt="20px"
              >
                Via our website:
                <Link color="blue.400" ml="2" href="https://www.mito.money">
                  www.mito.money
                </Link>
              </Heading>
            </ListItem>
          </UnorderedList>
          <Heading
            as="p"
            size="lg_light"
            color="rgb(33, 33, 33)"
            textTransform="initial"
            lineHeight="28px"
            pt="20px"
          >
            Mito.Money has a Data Protection Officer who is responsible for matters
            related to privacy, data protection and data security. Our Data
            Protection Officer can be contacted at
            <Link color="blue.400" ml="2" href="mailto:privacy@mito.money">
              privacy@mito.money
            </Link>
          </Heading>
        </Box>
      </Box>
    </Flex>
  );
};
export default AboutUsContent;
