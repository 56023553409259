import {
  Box,
  Image,
  Badge,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Text,
  Avatar,
  Divider,
  Link
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { FaExclamationCircle } from "react-icons/fa";
import { FaChevronRight } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  GetNotifications
} from "../../redux/modules/account";
import { toastrMsg } from "../../utils/toastrMsg";
import Vector from "../../assets/images/Vector1.svg";

const Notifications = () => {
  const {Notifications } = useAppSelector((state) => ({
    Notifications: state.account.Notifications,
  }));
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const handleClick = (referenceNumber: any) => {
    setPopoverOpen(false);
    navigate(`/support/my-tickets/${referenceNumber}`);
  };
  
  useEffect(() => {
    const getNotifications = async () => {
      let res: any = await dispatch(GetNotifications());
      if (res.error) {
        toastrMsg("Error", res.error.message);
      }
    };
    getNotifications();
  }, [dispatch, navigate]);

  const handleBadgeClick = () => {
    setPopoverOpen(!isPopoverOpen);
  };

  return (
    <>
      {Notifications?.length > 0 &&
        <Box alignItems="center">
          <Popover trigger="click" placement="bottom-start" isOpen={isPopoverOpen} onClose={() => setPopoverOpen(false)}>
            <PopoverTrigger>
              <Badge cursor="pointer" position="relative"  bg="#fff" width="30px" marginRight="10px" borderRadius="5px" onClick={handleBadgeClick}>
                <img src={Vector} /> 
                <span className="notification-count">{Notifications?.length}</span>
              </Badge>
            </PopoverTrigger>
            <PopoverContent minWidth="300px" zIndex="999999" bg="white" _focus={{boxShadow: "none"}}>
              <PopoverBody padding="0px" boxShadow="none">
                {Notifications.map((item: any, index: any) => (
                  <>
                    <Box display="flex" alignItems="center" justifyContent="space-between" padding="7px 10px" cursor="pointer" _hover={{
                      background: "gray.200",
                    }} key={index} onClick={() => handleClick(item.referenceNumber)} >
                      <Box display="flex" alignItems="center" justifyContent="start">
                        <Box mr="10px"><FaExclamationCircle fontSize="17px" color="var(--clr1)"/></Box>
                        <Box>
                          <Text pt='2' fontSize='lg' fontWeight="400">
                            New reply on ticket #{item.referenceNumber}
                          </Text>
                          <Text pt='2' fontSize='md' fontWeight="400" color="#333">
                            {moment(item.dateCreated).format('Do MMMM, h:mm A')}
                          </Text>
                        </Box>
                      </Box>
                      <Box>
                        <FaChevronRight fontSize="25px" color="#9B9B9B" />
                      </Box>
                    </Box>
                    <Divider />
                  </>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      }
    </>
  );
};

export default Notifications;
