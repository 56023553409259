import React, { useState } from "react";
import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Flex,
  useMediaQuery
} from "@chakra-ui/react";
import AppLink from "./AppLink";
import FAQContent from "../components/static/FAQContentPopup";
import TermsContent from "../components/static/TermsContentPopup";
import PrivacyContent from "../components/static/PrivacyContentPopup";

const StaticContentFrame = (props: any) => {
  const {toggleData} = props;
  const {isOpen, onOpen, onClose } = useDisclosure();
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const handleLinkClick = (event, contentType) => {
    event.preventDefault();
    if (contentType === "privacy") {
      setModalTitle("Privacy Policy");
      setModalContent(<PrivacyContent />);
    } else if (contentType === "terms") {
      setModalTitle("Terms of use");
      setModalContent(<TermsContent />);
    } else if (contentType === "faqs") {
      setModalTitle("FAQs");
      setModalContent(<FAQContent />);
    }
    onOpen();
  };

  return (
    <>
      {isMobile ? 
        <>          
          {toggleData && 
            <Flex 
              position={'absolute'}
              top={'8'} 
              left={'0'}
              boxShadow={'0 0px 2px 1px #ccc'}
              zIndex='99'
              backgroundColor="white"
              mt="4px"
              width="100%" 
              maxWidth="100%"
              flexDir={'column'}
            >
              <AppLink 
                href="#"
                p='12px 20px 0'
                width="100%" 
                onClick={(event) => handleLinkClick(event, "privacy")}
                style={{ textDecoration: 'none' }} // Removed underline for cleaner look
              >
                <Heading as="h4" size="md_light" variant="primary" w="100%">
                  Privacy Policy
                </Heading>
              </AppLink>
              
              <AppLink 
                href="#" 
                width="100%" 
                onClick={(event) => handleLinkClick(event, "terms")}
                style={{ textDecoration: 'none' }} // Removed underline for cleaner look
                p='12px 20px'
              >
                <Heading as="h4" size="md_light" variant="primary" w="100%">
                  Terms
                </Heading>
              </AppLink>
            </Flex>
          }
        </>
      :
        <Box>
          <AppLink href="#" ml="auto" p={{ sm: "0 1.4rem 0 1.4rem", lg: "0 2.4rem 0 2.6rem" }} onClick={(event) => handleLinkClick(event, "privacy")}>
            <Heading as="h4" size="md_light" variant="primary">
              Privacy Policy
            </Heading>
          </AppLink>
          
          <AppLink href="#" onClick={(event) => handleLinkClick(event, "terms")}>
            <Heading as="h4" size="md_light" variant="primary">
              Terms
            </Heading>
          </AppLink>
        </Box>
      }

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent py={15} background="#fff" maxWidth={{ sm: "90%", lg: "80%", xl: '70%' }} maxHeight={'80vh'} overflow="hidden">
          <ModalHeader>
            <Heading as="h4" fontSize={'30px'} fontWeight={'500'} variant="primary" textAlign="center">
              {modalTitle}
            </Heading>
          </ModalHeader>
          <ModalCloseButton  mt="5"/>
          <ModalBody overflowY="auto">
            <Box mt="20px" mb="28px" px={{ sm: "0", lg: "20" }}>
              {modalContent}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default StaticContentFrame;
