interface Config {
  apiUrl: string;
  apiKey: string;
  frameKey: string;
  checkoutTokenApi: string;
  domain: string;
  appleMerchantId: string;
}

interface ClientConfig {
  local: Config;
  dev: Config;
  alpha: Config;
  prod: Config;
}


const config: ClientConfig = {
  local: {
    apiUrl: "https://furp-02-uat.funtechcom.com/api/v1/",
    apiKey: "1972D9C6-563C-4944-90A1-94BACBEE3B1E",
    frameKey: process.env.REACT_APP_FRAME_ID,
    checkoutTokenApi: "https://api.sandbox.checkout.com/tokens",
    domain: "mito.money",
    appleMerchantId: "merchant.com.rhemito.web.nextjs.dev.sandbox",
  },
  dev: {
    apiUrl: "https://furp-02-uat.funtechcom.com/api/v1/",
    apiKey: "1972D9C6-563C-4944-90A1-94BACBEE3B1E",
    frameKey: process.env.REACT_APP_FRAME_ID,
    checkoutTokenApi: "https://api.sandbox.checkout.com/tokens",
    domain: "mito.money",
    appleMerchantId: "merchant.com.rhemito.web.nextjs.dev.sandbox",
  },
  alpha: {
    apiUrl: "https://api-alpha.funtechcom.com/api/v2/",
    apiKey: "d2783dc0-0ebc-4980-98ec-6203215f81ec",
    frameKey: process.env.REACT_APP_FRAME_ID,
    checkoutTokenApi: "https://api.checkout.com/tokens",
    domain: "alpha.rhemito.com",
    appleMerchantId: "merchant.com.rhemito.prod",
  },
  prod: {
    apiUrl: "https://api-alpha.funtechcom.com/api/v2/",
    apiKey: "d2783dc0-0ebc-4980-98ec-6203215f81ec",
    frameKey: process.env.REACT_APP_FRAME_ID,
    checkoutTokenApi: "https://api.checkout.com/tokens",
    domain: "alpha.rhemito.com",
    appleMerchantId: "merchant.com.rhemito.prod",
  },
};

// @ts-ignore
const envType: "local" | "dev" | "alpha" | "prod" = process.env.REACT_APP_ENV || "local";

export default config[envType];
