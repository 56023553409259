import MainLayout from "../../components/layout/MainLayout";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = ({ children }: any) => {  
  const isLoggedIn = localStorage.getItem("authToken");

  if (!isLoggedIn && window.location.pathname !== "/") {
    // Redirect the user to the login page if they are not logged in
    return <Navigate to={`/login?redirect=${encodeURIComponent(window.location.pathname)}`} />;
  }
  return <MainLayout><Outlet /></MainLayout>;
};

export default PrivateRoute;
